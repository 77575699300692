import { IReduxAction } from '@bees-web/core/types'
import { TRACK_PAGE } from './constants'
import { AnyAction, Dispatch } from 'redux'
import cookie from 'js-cookie'

import { uuidv4 } from '@bees-web/nfa-interactive-global'
import { page, track } from './actions'

export const trackAction: Function = (action: IReduxAction) => {
  return async (_dispatch: Dispatch<AnyAction>, getState): Promise<void> => {
    const anonymousId = cookie?.get('ajs_anonymous_id')?.replace(/"/g, '')
    const userId = cookie?.get('ajs_user_id')
      ? cookie.get('ajs_user_id').replace(/"/g, '')
      : null

    const actionSegmentMap = { [TRACK_PAGE]: page }

    const { type } = action
    const segmentTracking = actionSegmentMap[type]
      ? actionSegmentMap[type]
      : track

    const trackId = uuidv4()
    const hasLocaleState =
      getState().globals?.user?.language && getState().globals?.user?.country
    const locale = hasLocaleState
      ? `${getState().globals?.user?.language}-${
          getState().globals?.user?.country
        }`
      : null
    segmentTracking(action, trackId, userId, anonymousId, locale)

    const braze = window?.['braze']
    try {
      if (braze) {
        if (userId !== braze.getUser().getUserId()) braze.changeUser(userId)

        if (
          braze.logCustomEvent(`NFA ${type}`, {
            source: 'braze sdk',
            userId,
            event: type,
            anonymousId,
            ...action.payload,
            trackId,
          })
        ) {
          if (!userId) {
            braze.wipeData()
            braze.changeUser(anonymousId)
          }
        }
      }
    } catch (err) {
      console.info('error with braze', err)
    }
  }
}
